<template>
  <div class="content">
    <div class="ChangeTips">
      <div class="refundTitle">改签申请</div>
      <div class="refundTips" @click="lookRefundTips">
        <p>查看改签政策</p>
        <img
          src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-01-05/22/yuelvhuit325zNszon1609856971.png"
          alt=""
        >
      </div>
      <div class="refundPassengersList">
        <p class="refundPassengersListTitle">1.选择改签的乘客</p>
        <div class="PassengersListWrap">
          <div
            v-for="(item,index) in PassengersList"
            :key="index"
            :class="item.orderStatus !==5 ? 'PassengersListNo' :'PassengersList'"
          >
            <div class="selImgWrap">
              <img
                v-if="item.orderStatus===5"
                :src="item.isSel === true ? 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuipm6F6foTrL1609154518.png' : 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuiGQvkGohOde1609154493.png'"
                class="selImg"
                @click="selPerson(item)"
              >
            </div>
            <div class="personInfo">
              <div class="name">
                <p>{{ item.name }}</p>
                <p v-if="item.orderStatus !== 5" class="StatusText">{{ item.orderStatusText }}</p>
              </div>
              <div class="cardAndMobile">
                <p class="cardId">{{ item.idTypeText }}<span>{{ item.idCard }}</span></p>
                <p class="mobile">手机号<span>{{ item.phone }}</span></p>
              </div>
            </div>
            <div class="van-hairline--bottom" />
          </div>
        </div>
      </div>
      <div class="refundTravel">
        <p class="refundPassengersListTitle">2.选择改签的行程</p>
        <div class="refundTravelList">
          <div v-for="(item,index) in refundTravelList" :key="index" class="refundTravelListWrap">
            <!--                        <div class="selImg">-->
            <!--                            <img @click="selTravel(item)"-->
            <!--                                 :src="item.isSel ? 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuipm6F6foTrL1609154518.png' : 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuiGQvkGohOde1609154493.png'"-->
            <!--                                 class="selImg">-->
            <!--                        </div>-->
            <div class="refundTipsTravel">
              <div class="TravelDetails">
                <p>{{ item.date }}</p>
                <div class="TravelDetailss">
                  <p>{{ item.startCity }}</p>
                  <img
                    src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/21/yuelvhuiHEeDUSY4uW1609075234.png"
                    alt=""
                  >
                  <p>{{ item.endCity }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="changeDate">
        <p class="refundPassengersListTitle">3.选择改签的日期</p>
        <div class="changeTicketDate" @click="ChoiceDate">
          <img
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-01-14/18/yuelvhuieJeahHsG4h1610619991.png"
            alt=""
          >
          <p>{{ dateText }}</p>
        </div>
      </div>
    </div>
    <div class="lookChangTicket" @click="lookChangeTicked"><p>查看改签航班</p></div>
    <!--        展示说明-->
    <van-action-sheet v-model="showDisplayActive">
      <van-tabs v-if="VotePriceInfo" v-model="DisplayActive" scrollspy sticky class="DisplayActiveWrap">
        <van-tab title="票价明细" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuix6FjAWywIu1609225209.png"
              alt=""
            >
            <p>票价明细</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ orderInfo.depCity }}-{{ orderInfo.arrCity }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <p>机票¥{{ VotePriceInfo.adt.ticketPrice }}</p>
              <div class="van-hairline--left" />
              <p>{{ VotePriceInfo.adt.CabinName }}</p>
            </div>
            <div class="van-hairline--bottom" />
            <div v-if="VotePriceInfo.chd.length > 0" class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <p>机票¥{{ VotePriceInfo.chd.chdPrice }}</p>
              <div class="van-hairline--left" />
              <p>{{ VotePriceInfo.CabinName }}</p>
            </div>
          </div>
          <div class="TicketRemarks">
            <p>【儿童票】年龄在2周岁（含）-12周岁（不含）的常客，可预订儿童票</p>
          </div>
        </van-tab>
        <van-tab title="行李规定" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhui5fqqBtMpBX1609225230.png"
              alt=""
            >
            <p>免费行李规定</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ orderInfo.depCity }}-{{ orderInfo.arrCity }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="TicketBaggageContentInfo">
                <div class="chdBaggageInfo">
                  <div class="chdBaggageInfoT1">免费托运{{ VotePriceInfo.adt.luggage.consign }}KG</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2" />
                </div>
                <div class="van-hairline--bottom" />
                <div class="adtBaggageInfo">
                  <div class="chdBaggageInfoT1">免费手提行李{{ VotePriceInfo.adt.luggage.portable }}KG每人一件</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2">单件体积不超过{{ VotePriceInfo.adt.luggage.volume }}</div>
                </div>
                <div class="van-hairline--bottom" />
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div v-if="VotePriceInfo.chd.length > 0" class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="TicketBaggageContentInfo">
                <div class="chdBaggageInfo">
                  <div class="chdBaggageInfoT1">免费托运{{ VotePriceInfo.chd.luggage.consign }}</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2" />
                </div>
                <div class="van-hairline--bottom" />
                <div class="adtBaggageInfo">
                  <div class="chdBaggageInfoT1">免费手提行李{{ VotePriceInfo.chd.luggage.portable }}KG每人一件</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2">单件体积不超过{{ VotePriceInfo.chd.luggage.volume }}</div>
                </div>
                <div class="van-hairline--bottom" />
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="退改签说明" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>退订收费规则</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ orderInfo.depCity }}-{{ orderInfo.arrCity }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in VotePriceInfo.adt.refundCondition" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in VotePriceInfo.chd.refundCondition" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
          </div>
          <div class="TicketTitle" style="margin-top: 16px">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>同舱改期收费规则</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ orderInfo.depCity }}-{{ orderInfo.arrCity }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in VotePriceInfo.adt.rerouteCondition" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in VotePriceInfo.chd.rerouteCondition" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
          </div>
          <div class="TicketTitle" style="margin-top: 16px">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>签转他航条件</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ orderInfo.depCity }}-{{ orderInfo.arrCity }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfos">
              <p style="width: 100%">成人{{ VotePriceInfo.adt.transferRule }}</p>
              <p style="width: 100%">儿童{{ VotePriceInfo.chd.transferRule }}</p>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </van-action-sheet>
    <!--        选择日期-->
    <van-calendar v-model="showDate" title="选择出发日期" @confirm="onConfirm" />
  </div>
</template>

<script>
import Vue from 'vue'
import {
  ActionSheet,
  Calendar,
  Icon,
  Loading,
  Sidebar,
  SidebarItem,
  Toast,
  TreeSelect
} from 'vant'

Vue.use(Icon)
  .use(Calendar)
  .use(Loading)
  .use(Toast)
  .use(ActionSheet)
  .use(Sidebar)
  .use(SidebarItem)
  .use(TreeSelect)

import {
  getChangeDetail,
  getProductDes
} from '@/services/comeOn.js'

export default {
  name: 'TicketChange',
  data() {
    return {
      orderNo: '', // 订单号
      PassengersList: [], // 乘客列表
      selPersonList: [], // 选中的乘客列表
      refundTravelList: [], // 行程
      selRefundTravelList: [], // 选中的额行程
      showDisplayActive: false, // 展示说明
      DisplayActive: 0,
      VotePriceInfo: null, // 票价明细
      RefundsNoteInfo: {}, // 退改签说明
      showDate: false, // 选择日期
      dateText: '选择新航班日期'
    }
  },
  created() {

  },
  mounted() {
    this.orderNo = this.$route.query ? this.$route.query.orderNo : ''
    this.getChangeDetail()
  },
  methods: {
    // get改签详情
    getChangeDetail() {
      getChangeDetail({
        orderNo: ''
      }).then(res => {
        // if (Number(res.code) === 200) {
        // } else {
        //   // this.$router.push({
        //   //     path:'/TicketOrder'
        //   // })
        // }
      })
    },
    // 展示说明
    lookRefundTips() {
      getProductDes({
        flightInfoId: this.orderInfo.flightInfoId,
        tripType: 'OW', // 单程还是往返
        priceInfoId: this.orderInfo.priceInfoId
      }).then(res => {
        if (Number(res.code) === 200) {
          this.$nextTick(() => {
            this.VotePriceInfo = res.data// 展示说明
            this.showDisplayActive = true// 显示产品说明
          })
        }
      })
    },
    // 选择日期
    ChoiceDate() {
      this.showDate = true
    },
    // 转化日历组件返回的日期格式
    formatDate(date) {
      return `${date.getMonth() + 1}月${date.getDate()}日`
    },
    formatDates(date) {
      let month, day
      if (date.getMonth() + 1 < 10) {
        month = '0' + (date.getMonth() + 1)
      } else {
        month = date.getMonth() + 1
      }
      if (date.getDate() < 10) {
        day = '0' + date.getDate()
      } else {
        day = date.getDate()
      }
      return `${date.getFullYear()}-${month}-${day}`
    },
    // 选择日期确认
    onConfirm(date) {
      this.showDate = false
      this.dateText = this.formatDates(date)
    },
    // 选择乘客
    selPerson(item) {
      this.item = item
      item.isSel = !item.isSel
      if (item.isSel === true) {
        this.selPersonList.push(item)
      } else {
        this.selPersonList.forEach(e => {
          if (e.ticketId === item.ticketId) {
            this.AmountPrice(e, 1)
            this.selPersonList.splice(e, 1)
          }
        })
      }
    },
    // 选中行程
    selTravel(item) {
      item.clickNumber++// 点击次数
      this.selRefundTravelList = []// 清空选中的行程
      this.refundTravelList.forEach(e => {
        e.isSel = false
      })
      this.selRefundTravelList.push(item)
      item.isSel = !item.isSel
    },
    // 查看改签航班
    lookChangeTicked() {
      if (this.selPersonList.length === 0) {
        Toast('请选择乘车人')
        return false
      } else if (this.selRefundTravelList.length === 0) {
        Toast('请选择行程')
        return false
      } else if (this.dateText === '选择新航班日期') {
        Toast('请选择改签日期')
        return false
      }
      this.$router.push({
        path: '/tickedList',
        query: {
          startAddress: '', // 出发地
          endAddress: '', // 目的地
          startCode: '', // 出发地三字码
          endCode: '', // 目的地三字码
          startTime: this.dateText, // 日期
          ticketType: 0// 改签0
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  text-align: left;
}

.content {
  position: absolute;

  .refundTitle {
    width: 100%;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 17px;
    color: #333333;
    background-color: #fff;
  }

  /*提示*/

  .refundTips {
    width: 100%;
    height: 43px;
    background-color: #FCEEEE;
    line-height: 43px;
    font-size: 12px;
    padding: 0 14px;
    display: flex;
    position: relative;

    p {
      color: #E33F44;
    }

    img {
      margin-left: auto;
      width: 7px;
      height: 12px;
      position: absolute;
      top: 50%;
      margin-top: -6px;
      right: 14px;
    }
  }

  /*乘机人*/

  .refundPassengersList, .refundTravel {
    /*margin-top: 31px;*/
    padding: 31px 15px 18px;

    .refundPassengersListTitle {
      font-size: 17px;
      color: #000000;
      font-weight: 600;
    }

    .PassengersListWrap {
      .PassengersList, .PassengersListNo {
        display: flex;
        margin-top: 27px;

        .selImgWrap {
          width: 17px;

          img {
            width: 17px;
            height: 17px;
          }
        }

        .personInfo {
          margin-left: 17px;

          .name {
            font-size: 17px;
            font-weight: 600;
            display: flex;

            .StatusText {
              width: 53px;
              height: 18px;
              font-size: 12px;
              border: 1px solid #999999;
              color: #999999;
              border-radius: 3px;
              margin-left: 13px;
              text-align: center;
              line-height: 18px;
            }
          }

          .cardAndMobile {
            font-size: 14px;

            .cardId, .mobile {
              margin-top: 10px;

              span {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }

    .PassengersList {
      color: #333333;
    }

    .PassengersListNo {
      color: #999999;
    }
  }

  /*改签行程*/

  .refundTravel {
    .refundTravelListWrap {
      display: flex;
      margin-top: 26px;
    }

    /*退票列表*/

    .refundTravelList {
      .selImg {
        width: 17px;
        height: 17px;
      }

      /*退改签提示*/

      .refundTipsTravel {
        margin-left: 27px;
        color: #999999;

        .TravelDetails {
          font-weight: 600;
          font-size: 17px;
          display: flex;
          /*color: #000000;*/

          .TravelDetailss {
            display: flex;
            margin-left: 19px;

            img {
              width: 15px;
              height: 5px;
              margin: 5px 10px;
            }
          }
        }
      }
    }
  }

  /*改签日期*/

  .changeDate {
    padding: 31px 15px 18px;

    .refundPassengersListTitle {
      font-size: 17px;
      color: #000000;
      font-weight: 600;
    }

    .changeTicketDate {
      width: 144px;
      height: 37px;
      background-color: #E33F44;
      line-height: 37px;
      text-align: center;
      font-size: 14px;
      color: #ffffff;
      border-radius: 3px;
      display: flex;
      position: relative;
      padding: 0 10px;
      margin-top: 18px;

      img {
        width: 20px;
        height: 20px;
        margin-right: 7px;
        position: absolute;
        top: 50%;
        margin-top: -10px;
      }

      p {
        position: absolute;
        margin-left: 27px;
        top: 2px;
      }
    }
  }

  /*查看改签航班*/

  .lookChangTicket {
    position: absolute;
    bottom: 35px;
    padding: 0 15px;
    width: 100%;

    p {
      width: 100%;
      height: 45px;
      background-color: #E33F44;
      border-radius: 7px;
      font-size: 17px;
      color: #ffffff;
      text-align: center;
      line-height: 45px;
    }
  }
}
</style>
